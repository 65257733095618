<template>
  <div class="app-main-content review-form">
    <div class="inside-content">
      <div class="review-card">
        <div class="title-box">
          <div class="center">
            <a-range-picker
              :disabled="disabled"
              @change="changeRange" format="YYYY/MM/DD" value-format="YYYY/MM/DD" class="review-range" v-model="query.currentDateRange">
              <template #default>
                <div class="review-range-slot">
                  <div class="review-range-input star">
                    <img class="icon" :src="$toUrl('review-date.png')" alt="">
                    {{query.currentDateRange[0] ?? '开始时间'}}
                  </div>
                  <div class="text">至</div>
                  <div class="review-range-input end">
                    <img class="icon" :src="$toUrl('review-date.png')" alt="">
                    {{query.currentDateRange[1] ?? '结束时间'}}
                  </div>
                </div>
              </template>
            </a-range-picker>
            <div class="title-text">工作完成情况</div>
            <a-checkbox :disabled="disabled" v-model="preRanges.week" @change="changePreRange('week')">本周</a-checkbox>
            <a-checkbox :disabled="disabled" v-model="preRanges.moon" @change="changePreRange('moon')">本月</a-checkbox>
          </div>
          <div class="right">
            <a-button v-if="$route.query.oid" type="primary" @click="exportReport">导出</a-button>
          </div>
        </div>
        <div class="content">
          <div class="action">
            <a-button :disabled="disabled" class="item" type="primary" @click="$refs.modal.open(query.currentDateRange, false)">
              <i class="iconfont icon-jia" style="margin-right: 4px;"></i>
              项目选择
            </a-button>
            <a-button :disabled="disabled" class="item" type="primary" @click="openAddTaskModal('current')">
              <i class="iconfont icon-jia" style="margin-right: 4px;"></i>
              新增
            </a-button>
          </div>
          <!--        任务项列表-->
          <table class="review-table">
            <tr>
              <th style="width: 95px">序号</th>
              <th style="width: 218px">项目</th>
              <th style="width: 137px">阶段</th>
              <th style="width: 160px">任务类型</th>
              <th style="width: 288px">任务名称</th>
              <th style="width: 97px;">紧急程度</th>
              <th style="width: 98px;">预估工时</th>
              <th style="width: 125px; text-align: center; padding-left: 5px; padding-right: 5px;">要求完成日期</th>
              <th style="width: 254px;">完成质量</th>
              <th style="width: 94px;">完成度<span style="font-size: 12px">(%)</span></th>
              <th>任务状态记录</th>
              <th style="width: 60px;">操作</th>
            </tr>
            <tr v-for="(row, key) in form.rpt_content" :key="key">
              <!--            序号-->
              <td>{{getIndex(key + 1)}}</td>
              <!--            项目-->
              <td>{{row.project_name}}</td>
              <!--            阶段-->
              <td>{{row.phase_name}}</td>
              <!--            任务类型-->
              <td>{{row.issue_type}}</td>
              <!--            任务名称-->
              <td>{{row.issue_name}}</td>
              <!--            紧急程度-->
              <td style="text-align: center">
                <div
                  v-if="row.issue_level"
                  class="edp-tag"
                  :class="{
                  green: row.issue_level === '100',
                  yellow: row.issue_level === '300',
                  blue: row.issue_level === '200',
                  red: row.issue_level === '400',
                }"
                >
                  {{ row.issue_level_name }}
                </div>
              </td>
              <!--            预估工时-->
              <td :class="{'p-0': !disabled}">
                <a-input-number v-if="!disabled" v-model="row.schedule_worktime"></a-input-number>
                <template v-else>{{row.schedule_worktime}}</template>
              </td>
              <!--            要求完成日期-->
              <td :class="{'p-0': !disabled}" style="text-align: left">
                <a-date-picker v-if="!disabled" valueFormat="YYYY/MM/DD" v-model="row.actual_end_date" style="width: 100%; text-align: left"/>
                <template v-else>{{row.actual_end_date}}</template>
              </td>
              <!--            完成质量-->
              <td :class="{'p-0': !disabled}">
                <a-textarea v-if="!disabled" :autoSize="{minRows: 1, maxRows: 10}" v-model="row.quality" placeholder="请输入"></a-textarea>
                <template v-else>{{row.quality}}</template>
              </td>
              <!--            完成度-->
              <td :class="{'p-0': !disabled}">
                <a-input-number v-if="!disabled" v-model="row.complete_percent" placeholder="请输入"></a-input-number>
                <template v-else>{{row.complete_percent}}</template>
              </td>
              <!--            任务状态记录-->
              <td :class="{'p-0': !disabled}">
                <a-textarea v-if="!disabled" :autoSize="{minRows: 1, maxRows: 10}" v-model="row.task_states" placeholder="请输入"></a-textarea>
                <template v-else>{{row.task_states}}</template>
              </td>
              <td>
                <span v-if="!disabled" class="blue" @click="form.rpt_content.splice(key, 1)">删除</span>
              </td>
            </tr>
          </table>
          <div style="border-left: 1px solid #E8E8E8; border-right: 1px solid #e8e8e8;overflow: hidden">
            <edp-empty v-if="form.rpt_content.length === 0"></edp-empty>
          </div>
          <!--        总结-->
          <table class="review-table" style="margin-top: -1px">
            <tr v-for="(item, key) in form.self_content" :key="'self'+key">
              <td v-if="key === 0" class="center" :rowspan="form.self_content.length" style="width: 95px">个人总结</td>
              <td class="center" style="width: 137px">{{item.key}}</td>
              <td :class="{'p-0': !disabled}">
                <a-textarea v-if="!disabled" v-model="form.self_content[key].value" placeholder="请输入" autoSize class="one-line"></a-textarea>
                <template v-else>{{form.self_content[key].value}}</template>
              </td>
            </tr>
            <template v-if="btnPermissions.includes('审核') || form.status === '2'">
              <tr v-for="(item, key) in form.leader_content" :key="'leader'+key">
                <td v-if="key === 0" class="center" :rowspan="form.leader_content.length">领导总结</td>
                <td class="center" style="width: 137px">{{item.key}}</td>
                <td :class="{'p-0': btnPermissions.includes('审核')}">
                  <a-textarea v-if="btnPermissions.includes('审核')" v-model="form.leader_content[key].value" placeholder="请输入" autoSize class="one-line"></a-textarea>
                  <template v-else>{{form.leader_content[key].value}}</template>
                </td>
              </tr>
            </template>
          </table>
        </div>
      </div>
      <div class="review-card" style="margin-top: 10px;">
        <div class="title-box">
          <div class="center">
            <a-range-picker :disabled="disabled" format="YYYY/MM/DD" value-format="YYYY/MM/DD" class="review-range" v-model="query.nextDateRange">
              <template #default>
                <div class="review-range-slot">
                  <div class="review-range-input star">
                    <img class="icon" :src="$toUrl('review-date.png')" alt="">
                    {{query.nextDateRange[0] ?? '开始时间'}}
                  </div>
                  <div class="text">至</div>
                  <div class="review-range-input end">
                    <img class="icon" :src="$toUrl('review-date.png')" alt="">
                    {{query.nextDateRange[1] ?? '结束时间'}}
                  </div>
                </div>
              </template>
            </a-range-picker>
            <div class="title-text" style="margin-right: 137px">工作计划安排</div>
          </div>
        </div>
        <div class="content">
          <div class="action">
            <a-button :disabled="disabled" class="item" type="primary" @click="$refs.modal.open(query.nextDateRange, true)">
              <i class="iconfont icon-jia" style="margin-right: 4px;"></i>
              项目选择
            </a-button>
            <a-button :disabled="disabled" class="item" type="primary" @click="openAddTaskModal('next')">
              <i class="iconfont icon-jia" style="margin-right: 4px;"></i>
              新增
            </a-button>
          </div>
          <!--        任务项列表-->
          <table class="review-table">
            <tr>
              <th style="width: 95px">序号</th>
              <th style="width: 218px">项目</th>
              <th style="width: 137px">阶段</th>
              <th style="width: 160px">任务类型</th>
              <th style="width: 288px">任务名称</th>
              <th style="width: 97px;">紧急程度</th>
              <th style="width: 98px;">预估工时</th>
              <th style="width: 125px;padding-left: 5px; padding-right: 5px;text-align: center;">要求完成日期</th>
              <th style="width: 125px">配合人员</th>
              <th>要求完成质量</th>
              <th style="width: 60px;">操作</th>
            </tr>
            <tr v-for="(row, key) in form.plan_content" :key="key">
              <!--            序号-->
              <td>{{getIndex(key + 1)}}</td>
              <!--            项目-->
              <td>{{row.project_name}}</td>
              <!--            阶段-->
              <td>{{row.phase_name}}</td>
              <!--            任务类型-->
              <td>{{row.issue_type}}</td>
              <!--            任务名称-->
              <td>{{row.issue_name}}</td>
              <!--            紧急程度-->
              <td style="text-align: center">
                <div
                  v-if="row.issue_level"
                  class="edp-tag"
                  :class="{
                  green: row.issue_level === '100',
                  yellow: row.issue_level === '300',
                  blue: row.issue_level === '200',
                  red: row.issue_level === '400',
                }"
                >
                  {{ row.issue_level_name }}
                </div>
              </td>
              <!--            预估工时-->
              <td :class="{'p-0': !disabled}">
                <a-input-number v-if="!disabled" v-model="row.schedule_worktime"></a-input-number>
                <template v-else>{{row.schedule_worktime}}</template>
              </td>
              <!--            要求完成日期-->
              <td :class="{'p-0': !disabled}">
                <a-date-picker v-if="!disabled" valueFormat="YYYY/MM/DD" v-model="row.schedule_end_date" style="width: 100%;"/>
                <template v-else>{{row.schedule_end_date}}</template>
              </td>
              <!--            配合人员-->
              <td :class="{'p-0': !disabled}">
                <a-select
                  v-if="!disabled"
                  style="width: 200px;height: 32px;overflow: hidden;"
                  :maxTagCount="1"
                  showSearch
                  optionFilterProp="children"
                  mode="multiple"
                  v-model="row.collaborator_oid">
                  <a-select-option v-for="item in prjUserList[row.prjoid] || []" :value="item.user_oid" :key="item.user_oid">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
                <template v-else>{{row.collaborator.join(",")}}</template>
              </td>
              <!--            要求完成质量-->
              <td :class="{'p-0': !disabled}">
                <a-textarea v-if="!disabled" autoSize v-model="row.quality" placeholder="请输入"></a-textarea>
                <template v-else>{{row.quality}}</template>
              </td>
              <td>
                <span class="blue" @click="form.plan_content.splice(key, 1)">删除</span>
              </td>
            </tr>
          </table>
          <div style="border: 1px solid #E8E8E8; border-top: none;overflow: hidden">
            <edp-empty v-if="form.plan_content.length === 0"></edp-empty>
          </div>
        </div>
      </div>
      <div class="person">
        <div class="item">
          <div class="label">审核人</div>
          <a-select :disabled="disabled" v-model="form.auditor_oid" placeholder="请选择审核人">
            <a-select-option v-for="item in companyUserList" :key="item.oid" :value="item.oid">
              {{item.name}}
            </a-select-option>
          </a-select>
        </div>
        <div class="item">
          <div class="label">抄送人</div>
          <a-select :disabled="disabled"  style="min-width: 155px; width: auto;" v-model="form.cc_list" placeholder="请选择抄送人" showArrow showSearch mode="multiple" optionFilterProp="children">
            <a-select-option v-for="item in companyUserList" :key="item.oid" :value="item.oid">
              {{item.name}}
            </a-select-option>
          </a-select>
        </div>
      </div>
      <div class="action">
        <a-button @click="$router.push('list')">取消</a-button>
        <a-button v-if="btnPermissions.includes('修改')" type="primary" @click="save()">保存</a-button>
        <a-button v-if="btnPermissions.includes('提交')" type="primary" @click="updateStatus('1', '提交')">提交</a-button>
        <a-button v-if="btnPermissions.includes('审核')" type="primary" @click="updateStatus('2', '审核')">确认</a-button>
      </div>
    </div>
    <review-export-modal ref="modal" @setTask="exportTask"></review-export-modal>
    <create-task-modal ref="addTask" @reload="addTaskCallback"></create-task-modal>
  </div>
</template>

<script>
import {fetch} from "@/utils/request";
import ReviewExportModal from "@/view/workspace/review/reviewExportModal.vue";
import dayjs from "dayjs";
import catMixin from "@/utils/mixins/catMixin";
import CreateTaskModal from "@/components/createTaskModal.vue";
import {getRowPermission} from "@/view/workspace/review/review";
import appsettings from "@/utils/appsettings"

export default {
  components: {CreateTaskModal, ReviewExportModal},
  mixins: [catMixin],
  data() {
    return {
      cat: {
        self_title: [],
        leader_title: []
      },
      preRanges: {
        week: true,
        moon: false
      },
      query: {
        currentDateRange: [],
        nextDateRange: []
      },
      form: {
        status: "0",
        rpt_content: [],
        leader_content: [],
        self_content: [],
        plan_content: [],
        cc_list: [],
        auditor_oid: "",
        auditor_pic: "",
      },
      addTaskFrom: "",
      prjUserList: {},
      companyUserList: [],
      currentUserOid: ""
    }
  },
  computed: {
    disabled() {
      return !this.btnPermissions.includes('修改')
    },
    btnPermissions() {
      const result = getRowPermission(this.form, this.currentUserOid)
      if (this.$route.query.oid) {
        result["提交"] = true
      }
      return result
    },
  },
  watch: {
    'form.plan_content': {
      deep: true,
      handler() {
        this.form.plan_content.forEach((item) => {
          if (!this.prjUserList[item.prjoid]) {
            this.prjUserList[item.prjoid] = []
            this.getPrjUserList(item.prjoid)
          }
        })
      }
    }
  },
  async mounted() {
    const company_info = JSON.parse(localStorage.getItem("company_info"))
    this.currentUserOid = company_info.account_oid
    this.form.creator_oid = company_info.account_oid
    this.getCpyUserList()
    if (this.$route.query.oid) {
      this.getData()
    } else {
      let auditor_oid = localStorage.getItem("pre_report_auditor_oid" + this.currentUserOid)
      let cc_list = localStorage.getItem("pre_report_cc_list" + this.currentUserOid)
      if (auditor_oid) {
        this.form.auditor_oid = auditor_oid
      } else {
        fetch("post", "report/getAuditor").then(res => {
          if (res.status === 0) {
            this.form.auditor_oid = res.data.auditor_oid
          }
        })
      }
      if (cc_list) {
        this.form.cc_list = cc_list.split(",")
      }
      this.$nextTick(() => {
        this.$refs.modal.getOptions().then(async () => {
          this.changePreRange('week')
          this.$refs.modal.dateRange = this.query.currentDateRange
          this.$refs.modal.value = this.$refs.modal.options.map(item => item.value)
          await this.$refs.modal.handleOk()
          this.$refs.modal.dateRange = this.query.nextDateRange
          this.$refs.modal.next = true
          this.$refs.modal.value = this.$refs.modal.options.map(item => item.value)
          await this.$refs.modal.handleOk()
        })
      })
    }
  },
  methods: {
    exportReport() {
      let token = localStorage.getItem("token")
      let url = `${appsettings.apiroot}report/export?token=${token}&oids=${this.form.oid}`
      window.open(url)
    },
    async updateStatus(status, msg) {
      if (status === "1" && this.form.status === "0") {
        await this.save(true)
      }
      if (status === "2") {
        this.form.audit_time = dayjs().format("YYYY/MM/DD")
      }
      this.form.status = status
      this.save(true).then(() => {
        this.$message.success(msg + "成功")
      })
    },
    getData() {
      return fetch("post", "report/retrieve", {
        oid: this.$route.query.oid
      }).then(res => {
        if (res.status === 0) {
          if (res.data.datas.length === 0) {
            this.$message.error("数据不存在")
            this.$router.push("list")
          }
          const form = res.data.datas[0]
          form.plan_content = JSON.parse(form.plan_content).map(item => {
            return {
              ...item,
              schedule_end_date: item.schedule_end_date ? dayjs(item.schedule_end_date).format("YYYY/MM/DD") : ""
            }
          })
          form.rpt_content = JSON.parse(form.rpt_content).map(item => {
            let actual_end_date = item.actual_end_date
            if (actual_end_date && actual_end_date.indexOf("1900") !== 0) {
              actual_end_date = dayjs(item.actual_end_date).format("YYYY/MM/DD")
            } else {
              actual_end_date = ""
            }
            return {
              ...item,
              actual_end_date
            }
          })
          form.self_content = JSON.parse(form.self_content)
          form.leader_content = JSON.parse(form.leader_content)
          form.cc_list = form.cc_list ? JSON.parse(form.cc_list) : []
          form.cc_list = form.cc_list.map(item => item.oid)
          this.query.currentDateRange = [
            dayjs(form.rpt_start_time, "YYYY-MM-DD").format("YYYY/MM/DD"),
            dayjs(form.rpt_end_time, "YYYY-MM-DD").format("YYYY/MM/DD")
          ]
          this.query.nextDateRange = [
            dayjs(form.plan_start_time, "YYYY-MM-DD").format("YYYY/MM/DD"),
            dayjs(form.plan_end_time, "YYYY-MM-DD").format("YYYY/MM/DD")
          ]
          form.plan_content = form.plan_content.map(item => {
            return {
              ...item,
              collaborator: item.collaborator ? item.collaborator.split(",") : [],
              collaborator_oid: item.collaborator_oid ? item.collaborator_oid.split("/") :[]
            }
          })
          this.form = form
          this.$forceUpdate()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    getForm() {
      if (!Array.isArray(this.query.currentDateRange) || !this.query.currentDateRange[0] || !this.query.currentDateRange[1]) {
        return this.$message.error("请先选择汇报开始截止日期")
      }
      if (!this.form.auditor_oid) {
        return this.$message.error("请先选择抄送人")
      }
      const form = JSON.parse(JSON.stringify(this.form))
      form.rpt_name = this.query.currentDateRange[0] + "-" + this.query.currentDateRange[1] + "周报"
      form.rpt_start_time = this.query.currentDateRange[0]
      form.rpt_end_time = this.query.currentDateRange[1]
      form.rpt_content = form.rpt_content.map((item, index) => {
        return {
          ...item,
          seq: this.getIndex(index + 1)
        }
      })
      form.plan_start_time = this.query.nextDateRange[0] ?? ""
      form.plan_end_time = this.query.nextDateRange[1] ?? ""
      form.plan_content = form.plan_content.map((item, index) => {
        return {
          ...item,
          seq: this.getIndex(index + 1),
          collaborator: item.collaborator.join(","),
          collaborator_oid: item.collaborator_oid.join("/")
        }
      })
      form.auditor_name = this.companyUserList.find(item => item.oid === form.auditor_oid)?.name
      form.cc_list = form.cc_list.map(item => {
        let find = this.companyUserList.find(i => i.oid === item)
        if (find) {
          return {
            oid: item,
            name: find.name
          }
        }
        return {
          oid: item
        }
      })
      return form
    },
    save(disabledMsg = false) {
      if (this.$route.query.oid) {
        return fetch("post", "/report/update", this.getForm()).then(res => {
          if (res.status === 0) {
            if (!disabledMsg) {
              this.$message.success("保存成功")
            }
          }
        })
      } else {
        return fetch("post", "/report/create", this.getForm()).then(res => {
          if (res.status === 0) {
            if (!disabledMsg) {
              this.$message.success("保存成功")
            }
            localStorage.setItem("pre_report_auditor_oid" + this.currentUserOid, this.form.auditor_oid)
            localStorage.setItem("pre_report_cc_list" + this.currentUserOid, this.form.cc_list.join(","))
            return this.$router.replace({
              path: "form",
              query: {
                oid: res.data.oid
              }
            }).then(() => {
              return this.getData()
            })
          }
        })
      }
    },
    getIndex(n) {
      let num = Number(n)
      if (num > 0) {
        if (num < 10) {
          return "00" + num
        } else if (num < 99) {
          return "0" + num
        }
      }
      return num
    },
    getCpyUserList() {
      fetch("post", "/cpyaccount/retrieve/bycompany", ).then(res => {
        if (res.status === 0) {
          // 后端：第0个固定是当前用户的公司
          this.companyUserList = res.data.datas[0].accounts
        }
      })
    },
    getPrjUserList(prjoid) {
      fetch("post", "/prj/userlist/list", { prjoid }).then(res => {
        if (res.status === 0) {
          this.prjUserList[prjoid] = res.data
          this.$forceUpdate()
        }
      })
    },
    addTaskCallback(task) {
      if (this.addTaskFrom === "current") {
        this.form.rpt_content.push({
          issue_oid: task.oid,
          project_name: task.project_name,
          prjoid: task.prjoid,
          phase_name: task.phase_name,
          issue_type: task.issue_tag,
          issue_name: task.issue_name,
          issue_level: String(task.issue_level),
          issue_level_name: task.issue_level_name,
          schedule_worktime: task.schedule_worktime,
          actual_end_date: task.schedule_end_date.indexOf("1900") === 0 ? "" : task.schedule_end_date,
          quality: "",
          complete_percent: "0",
          task_states: ""
        })
      } else if (this.addTaskFrom === "next") {
        this.form.plan_content.push({
          issue_oid: task.oid,
          project_name: task.project_name,
          prjoid: task.prjoid,
          phase_name: task.phase_name,
          issue_type: task.issue_tag,
          issue_name: task.issue_name,
          issue_level: String(task.issue_level),
          issue_level_name: task.issue_level_name,
          schedule_worktime: task.schedule_worktime,
          schedule_end_date: task.schedule_end_date,
          quality: task.issue_desc,
          complete_percent: "0",
          task_states: "",
          collaborator: task.issue_assist_name,
          collaborator_oid: task.issue_assist ? task.issue_assist.split("/") : []
        })
      }
    },
    openAddTaskModal(from) {
      this.addTaskFrom = from
      this.$refs.addTask.open()
    },
    afterGetCat() {
      if (!this.$route.query.oid) {
        this.form.self_content = this.cat.self_title.map(item => {
          return {
            key: item.cat_name,
            value: ""
          }
        })
        this.form.leader_content = this.cat.leader_title.map(item => {
          return {
            key: item.cat_name,
            value: ""
          }
        })
      }
    },
    exportTask(data, next) {
      if (data.length > 0) {
        if (next) {
          this.form.plan_content.push(...data.filter(item => {
            return !this.form.plan_content.find(content => content.issue_oid === item.core.oid)
          }).map(item => {
            return {
              prjoid: item.core.prjoid,
              issue_oid: item.core.oid,
              project_name: item.project_name,
              phase_name: item.phase_name,
              issue_type: item.core.issue_tag,
              issue_name: item.core.issue_name,
              issue_level: String(item.core.issue_level),
              issue_level_name: item.issue_level_name,
              schedule_worktime: item.core.schedule_worktime,
              schedule_end_date: item.core.schedule_end_date,
              collaborator: item.assists.map(item => item.name),
              collaborator_oid: item.assists.map(item => item.oid),
              quality: item.core.issue_desc,
              complete_percent: item.core.complete_percent,
              task_states: ""
            }
          }))
        } else {
          this.form.rpt_content.push(...data.filter(item => {
            return !this.form.rpt_content.find(content => content.issue_oid === item.core.oid)
          }).map(item => {
            return {
              issue_oid: item.core.oid,
              project_name: item.project_name,
              phase_name: item.phase_name,
              issue_type: item.core.issue_tag,
              issue_name: item.core.issue_name,
              issue_level: String(item.core.issue_level),
              issue_level_name: item.issue_level_name,
              schedule_worktime: item.core.schedule_worktime,
              actual_end_date: item.core.schedule_end_date.indexOf("1900") === 0 ? "" : item.core.schedule_end_date,
              quality: "",
              complete_percent: item.core.complete_percent,
              task_states: ""
            }
          }))
        }
      }
    },
    // type: week | moon
    changePreRange(type) {
      if (this.preRanges[type]) {
        if (type === 'week') {
          this.query.currentDateRange = [
            dayjs().startOf("week").add(1, 'day').format("YYYY/MM/DD"),
            dayjs().endOf("week").add(1,'day').format("YYYY/MM/DD")
          ]
          this.query.nextDateRange = [
            dayjs().startOf("week").add(1, 'day').add(1, "week").format("YYYY/MM/DD"),
            dayjs().endOf("week").add(1,'day').add(1, "week").format("YYYY/MM/DD")
          ]
          this.preRanges.moon = false
        } else {
          this.query.currentDateRange = [
            dayjs().startOf("month").format("YYYY/MM/DD"),
            dayjs().endOf("month").format("YYYY/MM/DD")
          ]
          this.query.nextDateRange = [
            dayjs().startOf("month").add(1, "month").format("YYYY/MM/DD"),
            dayjs().startOf("month").add(1, "month").endOf("month").format("YYYY/MM/DD")
          ]
          this.preRanges.week = false
        }
      }
    },
    changeRange() {
      this.preRanges.moon = false
      this.preRanges.week = false
    },
    emptyPadding(record) {
      console.log(record)
      return {
        props: {
          class: "empty-padding"
        }
      }
    },
  }
}
</script>

<style lang="less" scoped>
.review-table {
  width: 100%;
  border-collapse: collapse;
  &, td, th {
    border: 1px solid #E8E8E8;
  }
  th {
    font-size: 16px;
    font-weight: 500;
    background-color: #F8F8F8;
    padding: 8px 13px;
  }
  td {
    font-size: 14px;
    color: #494949;
    vertical-align: top;
    &:not(.p-0) {
      padding: 8px 12px;
    }
    .blue:not(.edp-tag) {
      color: #1D7BFF;
      cursor: pointer;
    }
  }
  td.center {
    vertical-align: middle;
    text-align: center;

    color: #303030;
    font-weight: 500;
    font-size: 14px;
    padding-top: 0;
    padding-bottom: 0;
    height: 42px;
  }
  td textarea.one-line {
    min-height: 39px;
    padding-top: 8px;
    padding-bottom: 0;
  }
  td.p-0 {
    position: relative;
    height: 0;
    >.ant-calendar-picker {
      /deep/ .ant-calendar-picker-input {
        border: none;
        text-align: center;
        padding: 0;
        color: #494949;
      }
      /deep/ i.anticon {
        display: none;
      }
    }
    >.ant-input {
      border: none;
      border-radius: 0;
      color: #494949;
    }
    >.ant-select {
      border: 1px solid rgba(0,0,0,0);
      &.ant-select-open {
        border-color: fadeout(@primary-color, 50%);
      }
      /deep/ .ant-select-selection {
        border: none;
      }
    }
    /deep/ .ant-input-number {
      border: none;
      height: 30px;
      width: 100%;
      .ant-input-number-handler-wrap{
        display: none;
      }
      .ant-input-number-input-wrap {
        height: 100%;
        width: 100%;
      }
      .ant-input-number-input {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.review-form {
  .inside-content {
    background-color: white;
    >.review-card {
      padding-top: 10px;
      background-color: white;
      .title-box {
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        > .center {
          display: flex;
          align-items: center;
          .ant-checkbox-wrapper + .ant-checkbox-wrapper {
            margin-left: 2px;
          }
          .title-text {
            margin: 0 10px;
            color: #000;
            font-size: 18px;
            font-weight: 700;
          }
        }
        > .right {
          position: absolute;
          right: 24px;
        }
      }
      >.content {
        border-top: 1px solid #E8E8E8;
        padding: 15px 11px;
        >.action {
          display: flex;
          margin-bottom: 16px;
          .item {
            margin-right: 12px;
          }
        }
      }
    }
    >.person {
      display: flex;
      align-items: center;
      margin: 24px 0;
      padding-left: 12px;
      .item {
        margin-right: 42px;
        display: flex;
        align-items: center;
        .label {
          font-size: 16px;
          color: #494949;
          margin-right: 8px;
        }
        .ant-select {
          width: 155px;
        }
      }
    }
    >.action {
      padding-left: 12px;
      padding-bottom: 55px;
      .ant-btn {
        margin-right: 12px;
      }
    }
  }
}
</style>