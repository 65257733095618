<template>
  <a-modal
    title="项目选择"
    v-model="visible"
    @ok="handleOk"
    @cancel="handleCancel"
    :bodyStyle="{padding: '15px 24px;'}"
    :confirm-loading="confirmLoading"
  >
    <div class="alert">
      选择需要汇报的项目：
    </div>
    <a-spin :spinning="loading">
      <a-checkbox-group
        v-model="value"
        :options="options"
      />
    </a-spin>
  </a-modal>
</template>

<script>
import {fetch} from "@/utils/request";

export default {
  name: "reviewExportModal",
  emits: ['setTask'],
  data() {
    return {
      dateRange: [],
      visible: false,
      confirmLoading: false,
      value: [],
      options: [],
      loading: false,
      next: false,
    }
  },
  created() {
    this.getOptions()
  },
  methods: {
    async getOptions() {
      await fetch("post", "/report/getProject").then(res => {
        if (res.status === 0) {
          this.options = res.data.map(item => {
            return {
              label: item.project_name,
              value: item.oid
            }
          })
        }
      })
    },
    open(dateRange, next) {
      this.dateRange = dateRange
      this.value = this.options.map(item => item.value)
      this.visible = true
      this.next = next
    },
    async handleOk() {
      this.confirmLoading = true
      let query = {}
      if (Array.isArray(this.dateRange)) {
        if (this.dateRange[0]) {
          query.schedule_start_date = this.dateRange[0]
        }
        if (this.dateRange[1]) {
          query.schedule_end_date = this.dateRange[1]
        }
      }
      return await fetch("post", "/report/getIssue", {
        prjoids: this.value.join(','),
        next: this.next,
        ...query
      }).then(res => {
        if (res.status === 0) {
          for (let i = 0; i < res.data.length; i++) {
            if (res.data[i].core.schedule_end_date.indexOf("1900") === 0) {
              res.data[i].core.schedule_end_date = ""
            }
          }
          this.$emit("setTask", res.data, this.next)
          this.visible = false
        } else {
          this.$message.error(res.message)
        }
      }).finally(() => {
        this.confirmLoading = false
      })
    },
    handleCancel() {

    }
  }
}
</script>

<style scoped lang="less">
.alert {
  font-size: 16px;
  color: #303030;
}
.ant-checkbox-group {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  height: 500px;
  overflow-y: auto;
  /deep/ .ant-checkbox-group-item {
    margin-bottom: 10px;
    span {
      color: #303030;
    }
  }
}
</style>